<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix">
      <el-button
        style="float: right"
        type="primary"
        @click="opendialog()"
        >新增词条</el-button
      >
    </div>
    <!--添加-->
    <el-dialog :title="form._id ? '编辑词条' : '新增词条'" :visible.sync="dialogFormVisible" :close-on-click-modal="false">
        <el-form :model="form" ref="form" :inline="true">
          <el-form-item label="词条内容" prop="content"
            :rules="[
              { required: true, message: '词条内容不能为空'},
            ]"
          >
            <el-input class="w250" v-model="form.content" autocomplete="off" placeholder="请输入词条内容"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="clocedialog()">取 消</el-button>
          <el-button type="primary" v-if="!form._id" @click="creatediagnosis(form)">添 加</el-button>
          <el-button type="primary" v-if="form._id" @click="editdiagnosis(form)">更 新</el-button>
        </div>
      </el-dialog>
    <!--添加-->
    <el-table
      :data="items"
      style="width: 100%; margin-bottom: 20px"
      row-key="_id"
      border
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column prop="content" label="词条内容"> </el-table-column>
      <el-table-column prop="creator" label="创建人"></el-table-column>
      <el-table-column prop="editor" label="修改人"></el-table-column>
      <el-table-column prop="createdAt" label="创建时间" :formatter="formatTime"></el-table-column>
      <el-table-column prop="updatedAt" label="修改时间" :formatter="formatTime"></el-table-column>
      <el-table-column label="操作" fixed="right" width="200">
        <template slot-scope="scope">
          <el-button size="mini" @click="handleedit(scope.row)">编辑</el-button>
          <el-button size="mini" type="danger" @click="handleDelete(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      @current-change="handlePageChange"
      @size-change="handlePageSizeChange"
      :current-page="currentPage"
      :page-sizes="[5, 10, 20, 40]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </el-card>
</template>
<script>
import unitsData from '@/utils/unit';
export default {
  data() {
    return {
      dialogFormVisible: false,
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页数据量
      total: 0, // 总数据量
      items: [],
      timeslot:"",
      search: "",
      searchdrug: "",
      searchdate: "",
      units:unitsData,
      form: {
        _id:"",
        content:"",
        creator:"",
        editor:"",
        createdAt:"",
        updatedAt:"",
      },
    };
  },
  computed: {},
  methods: {
    clocedialog(){
      this.dialogFormVisible = false;
    },
    opendialog(){
      this.dialogFormVisible = true;
      this.form = {}
    },
    editdiagnosis(data) {
      this.$api.templates.editdiagnosis(data).then((res) => {
        this.$message({
          message: res.data.msg,
          type: "success",
        });
        this.loadItems();
        this.dialogFormVisible = false;
      });
    },
    handleedit(data) {
      this.dialogFormVisible = true;
      this.form = JSON.parse(JSON.stringify(data))
    },
    handleDelete(data) {
      this.$api.templates.deletediagnosis(data).then((res) => {
        this.$message({
          message: res.data.msg,
          type: "success",
        });
        this.loadItems();
      });
    },
    creatediagnosis(data) {
      this.$refs.form.validate((valid) => {
          if (valid) {
            this.$api.templates.creatediagnosis(data).then((res) => {
              this.$message({
                message: res.data.msg,
                type: "success",
              });
              this.dialogFormVisible = false;
              this.loadItems();
            });
          } else {
            this.$message({
                message: "请按规则填写",
                type: "warning",
            });
            return false;
          }
      });
    },
    handlePageChange(page) {
      this.currentPage = page;
      this.loadItems();
    },
    handlePageSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.loadItems();
    },
    loadItems() {
      const data = {
        currentPage: this.currentPage,
        pageSize: this.pageSize,
      };
      this.$api.templates.diagnosislist(data).then((res) => {
        this.currentPage = res.data.pagination.currentPage;
        this.pageSize = res.data.pagination.pageSize;
        this.total = res.data.pagination.total;
        this.items = res.data.data;
      });
    },
    formatTime(row,column){
        let data = row[column.property]
        let dtime = new Date(data)
        const year = dtime.getFullYear()
        let month = dtime.getMonth() + 1
        if (month < 10) {
            month = '0' + month
        }
        let day = dtime.getDate()
        if (day < 10) {
            day = '0' + day
        }
        let hour = dtime.getHours()
        if (hour < 10) {
            hour = '0' + hour
        }
        let minute = dtime.getMinutes()
        if (minute < 10) {
            minute = '0' + minute
        }
        let second = dtime.getSeconds()
        if (second < 10) {
            second = '0' + second
        }
        return year+ '-' + month+ '-' + day
      }
  },
  created() {
    this.loadItems();
  },
};
</script>
<style>
.el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 250px;
}
.w250{
  width: 250px;
}
.w120{
  width: 120px;
}
.flex{
  display: flex;
}
.justifyend{
  justify-content: end;
}
button:focus {
    outline: none;
}
.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}

.box-card {
  width: 100%;
}
</style>
